html,
body,
h1,
h2,
h3,
ul,
li,
a,
i,
select,
label,
input,
div,
span,
button,
small,
strong,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}
