@use 'sass:math';
@import '../styles/_variables.scss';

label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

select {
  display: block;
  background-color: $whiteColor;
  box-shadow: 0 0 0 1px $primaryLightenColor;
  font-size: $pSize;
  font-weight: 400;
  border-radius: $borderRadiusSmall;
  color: $primaryDarkenColor;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 1px $primaryColor;
  }
}

input {
  display: block;
  box-shadow: inset 0 0 1px $primaryColor;
  width: 100%;
  border-radius: $borderRadius;
  padding: math.div($paddingSmall, 2) $paddingSmall;
  transition: border 0.2s ease-in-out;
  font-weight: 400;
  font-size: $pSize;
  outline: none;

  &.short {
    width: 50%;
  }

  &.noBorderRadius {
    border-radius: 0;
  }

  &.big {
    padding: math.div($padding, 2);
  }

  &::placeholder {
    color: $greyColor;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
  &:hover:not(:disabled),
  &:focus {
    border-color: $hoverColor;
  }
  &[type='checkbox'] {
    position: relative;
    cursor: pointer;
    height: $pSize;
    width: $pSize;
    border: none;
    box-shadow: none;
    margin-right: 1em;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: $pSize;
      height: $pSize;
      top: -3px;
      left: -3px;
      border: 2px solid $primaryColor;
      border-radius: 4px;
      background: $whiteColor;
    }
    &:checked {
      &:before {
        background: $primaryColor;
      }
      &:after {
        content: '';
        display: block;
        width: 4px;
        height: 8px;
        border: solid $whiteColor;
        border-radius: 1px;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: absolute;
        top: 0px;
        left: 3px;
      }
    }
  }
}
