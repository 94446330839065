@use 'sass:math';

// https://coolors.co/05299e-f8f7ff-7f96ff-ffeedd-ffd8be

$international-klein-blue: #05299e;
$ghost-white: #faf9ff;
$cornflower-blue: #7f96ff;
$antique-white: #ffeedd;
$unbleached-silk: #ffd8be;

// Colors:
$blackColor: #020a13;
$greenColor: #26bf88;
$greyColor: #a2aab3;
$redColor: #ee6666;
$orangeColor: #ffbc65;
$blueColor: $cornflower-blue;
$whiteColor: #ffffff;

$primaryColor: $blueColor;
$primaryDarkenColor: darken($primaryColor, 5%);
$primaryLightenColor: lighten($primaryColor, 5%);

$bgColor: $ghost-white;
$hoverColor: $antique-white;
$textColor: $blackColor;

// Sizes:
$borderRadius: 8px;
$borderRadiusBig: $borderRadius * 2;
$borderRadiusSmall: math.div($borderRadius, 3) * 2;
$padding: 20px;
$paddingSmall: math.div($padding, 2);
$paddingBig: $padding * 1.5;
$menuWidth: 220px;
$maxContainerWidth: 1100px;

// Box shadows:
$boxShadow: 0 1px 4px $hoverColor;
$boxShadowLight: 0 1px 4px 2px $hoverColor;

// Font:
$h1Size: 28px;
$h2Size: 22px;
$h3Size: 15px;
$pSize: 13px;
$smallSize: 11px;
$verySmallSize: 9px;
$fontFamilySansSerif: 'Inter', Helvetica, Arial, sans-serif;
$fontFamilyCourier: courier, sans-serif;

// Screen:
$maxLaptop: 1200px;
$maxTablet: 900px;
$maxMobile: 640px;
