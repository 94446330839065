@import './../../../styles/_variables.scss';

.page--magic-link {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: $h1Size;
    font-weight: bold;
  }
}
