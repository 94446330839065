@use 'sass:math';
@import './../../styles/_variables.scss';
@import './../../styles/_mixins.scss';

.transactions {
  .subtitle {
    height: $pSize;
    margin-top: math.div($paddingSmall, 2);
    font-weight: normal;
    font-size: $smallSize;
    .loading {
      min-width: 180px;
    }
  }

  .filter {
    display: flex;
    align-items: end;
    svg {
      height: $h2Size;
      cursor: pointer;
      stroke: $greyColor;
      &:hover {
        stroke: $primaryDarkenColor;
      }
    }
  }

  .transactions--load-more {
    color: $primaryLightenColor;
    cursor: pointer;
    margin-top: $padding;

    &:hover {
      color: $primaryColor;
    }
  }

  .transactions--search {
    margin: $padding 0;
  }

  .transaction {
    margin-top: $paddingSmall;
    padding: $paddingSmall;
    border-radius: $borderRadius;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: lighten($bgColor, 1%);
    }

    &.focused,
    &.selected {
      box-shadow: inset 0 0 1px $primaryColor;
      background: $whiteColor;
    }

    &.selected {
      .transaction--detail {
        max-height: 200px;
      }
    }

    &.pending {
      color: $greyColor;
      img {
        z-index: 0;
        filter: grayscale(1);
        opacity: 0.5;
      }
    }
  }

  .transaction--summary {
    display: flex;
    align-items: center;

    .transaction--block {
      display: flex;
      align-items: center;
      min-height: 30px;
      margin: 0 $paddingSmall;
    }
    .transaction--emoji {
      width: 2%;
      min-width: 10px;
      text-align: center;
      cursor: pointer;
    }
    .transaction--name {
      display: flex;
      align-items: center;
      width: 38%;
      overflow: hidden;
      cursor: pointer;

      .transaction--name-block {
        font-weight: 600;
      }

      svg {
        height: $h3Size;
        margin-right: $paddingSmall;
        stroke: $greenColor;
      }

      .transaction--pending {
        display: inline-block;
        font-size: $smallSize;
        font-weight: 400;
        margin-left: $paddingSmall;
      }
    }

    .transaction--amount {
      display: flex;
      justify-content: end;
      min-width: 70px;
      width: 12%;
      text-align: right;

      .digits {
        font-size: 0.7em;
      }
    }

    .transaction--created-at {
      display: flex;
      justify-content: center;
      min-width: 100px;
      width: 12%;
      font-size: $smallSize;
      color: $primaryLightenColor;
      text-align: center;
    }

    .transaction--project {
      display: flex;
      justify-content: center;
      min-width: 100px;
      width: 16%;
      align-items: center;

      .transaction--project-block {
        display: flex;
        align-items: center;
        min-height: 30px;
        width: 100%;
        padding: 4px $paddingSmall;
        border-radius: $borderRadius;

        .transaction--project-name {
          min-width: 0;
          margin-left: 4px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &:hover,
        &.transaction--project-selected {
          cursor: pointer;
          background: $bgColor;
        }
      }
    }

    .transaction--actions {
      display: flex;
      min-width: 80px;
      width: 15%;
      justify-content: end;

      .transaction--action {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $borderRadius;
        width: $h1Size;
        height: $h1Size;
        overflow: hidden;
        font-size: $verySmallSize;
        color: $primaryLightenColor;
        text-align: center;

        &.transaction--action-large {
          width: $h1Size * 2 + $padding;
        }

        &:not(:last-child) {
          margin-right: $padding;
        }

        svg {
          height: 50%;
          stroke: $greyColor;
        }

        img {
          height: 120%;
        }

        &.canHover:hover {
          opacity: 0.8;
          box-shadow: 0 0 0 1px $primaryDarkenColor;
          cursor: pointer;
          &:not(.archived):not(.shared):not(.reimbursed) {
            svg {
              stroke: $primaryDarkenColor;
            }
          }
        }

        &.archived,
        &.shared,
        &.reimbursed {
          background: $primaryDarkenColor;
          box-shadow: 0 0 0 1px $primaryDarkenColor;
          svg {
            stroke: $bgColor;
          }
        }
        &.reimbursed {
          box-shadow: 0 0 0 2px $greenColor;
        }
      }
    }

    .transaction--chevron {
      display: flex;
      justify-content: end;
      width: 5%;
      min-width: 20px;
      cursor: pointer;

      svg {
        width: $smallSize;
        stroke: $primaryLightenColor;
      }

      &.reverse svg {
        transform: rotate(180deg);
      }
    }

    .transaction--sort {
      color: $primaryLightenColor;
      font-size: $smallSize;
      cursor: pointer;

      &:hover {
        color: $primaryDarkenColor;
      }

      &.active {
        color: $primaryDarkenColor;
        font-weight: 600;
      }
    }
  }

  .transaction--detail {
    display: flex;
    box-sizing: content-box;
    overflow: hidden;
    max-height: 0;
    padding: 0 $padding;
    font-size: $pSize;
    transition: max-height 0.1s ease-in-out;

    ul {
      flex: 1;
      margin-top: $padding;

      li {
        display: flex;
        align-items: baseline;
        margin: $padding 0;

        span {
          color: $greyColor;
          margin-left: $paddingSmall;
          font-size: $smallSize;
        }

        svg,
        img {
          width: $pSize;
          margin-right: $paddingSmall;
          stroke: $greyColor;
        }
        .transaction--detail-shared svg {
          stroke: $primaryColor;
        }
        .transaction--detail-archived svg {
          stroke: $greyColor;
        }
        .transaction--detail-reimbursed svg {
          stroke: $greenColor;
        }

        .bank-name {
          font-weight: 500;
        }

        .very-small {
          color: $greyColor;
        }
      }
      &:last-child li {
        display: flex;
        justify-content: end;
        align-items: center;

        a {
          display: flex;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }

        input {
          margin-right: 6px;
        }
      }
    }
  }

  footer {
    cursor: pointer;
    margin-top: $padding * 2;
    font-size: $smallSize;
    color: $primaryLightenColor;
  }
}

@media only screen and (max-width: $maxLaptop) {
  .transaction--chevron {
    display: none !important;
  }
}
@media only screen and (max-width: $maxTablet) {
  .transaction--project {
    min-width: 20px !important;
    .transaction--project-block {
      justify-content: center;
    }
    .transaction--project-name {
      display: none !important;
    }
  }
}
@media only screen and (max-width: $maxMobile) {
}
