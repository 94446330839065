@import './../../../styles/_variables.scss';
@import './../../../styles/_mixins.scss';

.page--login {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: $h1Size;
    font-weight: bold;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .fadeIn {
    animation: FadeIn 0.5s ease-in-out;
  }
}
