@use 'sass:math';
@import './../../styles/_variables.scss';
@import './../../styles/_mixins.scss';

.accounts {
  .accounts-graphs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $padding * 2;

    .accounts-graphs--graph {
      background-color: $whiteColor;
      padding: $paddingSmall $padding;
      border-radius: $borderRadiusBig;

      h3 {
        text-align: center;
        margin-bottom: $padding;
      }

      .recharts-cartesian-axis-line {
        fill: red;
      }
      text {
        font-size: $smallSize;
        fill: $greyColor;
      }

      .accounts-graphs--benefits {
        font-size: $smallSize;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: $maxLaptop) {
  .accounts .accounts-graphs {
    grid-template-columns: 1fr;
  }
}
