@use 'sass:math';
@import './../../styles/_variables.scss';
@import './../../styles/_mixins.scss';

.navigation-menu {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  min-height: 0;
  max-height: 100vh;
  width: $menuWidth;
  box-shadow: 1px 0 0 0 darken($bgColor, 1%);
  background: $bgColor;
  font-weight: 500;
  z-index: 1;
}

.navigation-menu--content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $padding;

  a {
    display: flex;
    align-items: center;
    margin-bottom: $paddingSmall;
    padding: $paddingSmall;
    color: $textColor;
    font-weight: 600;
    text-decoration: none;
    border-radius: $borderRadius;
    transition: all 0.2s ease-in-out;

    svg {
      margin-right: $padding;
      width: $h2Size;
      height: $h2Size;
    }

    &:hover:not(.active) {
      background: $bgColor;
    }

    &.active {
      background: $blueColor;
      color: $bgColor;
    }
  }
}

.navigation-menu--footer {
  display: flex;
  align-items: center;
  padding: $padding;

  img {
    border-radius: $borderRadius;
    height: $h1Size;
  }

  .navigation-menu--footer-name {
    margin-left: $paddingSmall;
    font-size: $h3Size;
    font-weight: 400;
  }
}

@media only screen and (max-width: $maxTablet) {
  .navigation-menu {
    width: auto;

    .navigation-menu--content a svg {
      margin: 0;
    }

    .navlink-text {
      display: none;
    }
  }
}
