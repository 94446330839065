@import './../../styles/_variables.scss';

.jauged-category {
  & > div {
    margin-bottom: $padding;
  }

  .jauged-category--title {
    font-weight: 600;
    color: $primaryLightenColor;
    text-decoration: none;
    &:hover {
      color: $primaryColor;
    }
  }

  .jauged-category--total {
    font-weight: 600;
    font-size: $smallSize;
    color: $greyColor;
  }
}
