@use 'sass:math';
@import './../../styles/_variables.scss';
@import './../../styles/_mixins.scss';

.flying-menu {
  position: relative;

  .modal-background {
    z-index: 200;
  }

  .flying-menu--block {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 180px;
    max-height: 345px;
    box-shadow: 0 1px 4px $greyColor, inset 0 0 1px $primaryColor;
    border-radius: $borderRadius;
    background: $whiteColor;
    text-align: left;
    color: $textColor;
    animation: FadeIn 0.2s ease-in-out;
    z-index: 210;

    input {
      border-radius: $borderRadius $borderRadius 0 0;
    }

    ul {
      flex: 1;
      overflow-y: auto;

      li {
        padding: $paddingSmall $paddingSmall;
        cursor: pointer;

        &:hover,
        &.focused {
          background: $bgColor;
        }
      }
    }
  }

  .flying-menu--block {
    &.bottom {
      top: auto;
      bottom: 0;
    }
    &.right {
      left: auto;
      right: 0;
    }
    &.full-screen {
      $modalWidth: 400px;
      $modalHeight: 430px;

      position: fixed !important;
      top: calc(50% - #{math.div($modalHeight, 2)}) !important;
      left: calc(
        50% - #{math.div($modalWidth, 2)} + #{math.div($menuWidth, 2)}
      ) !important;
      right: auto !important;
      bottom: auto !important;
      height: $modalHeight;
      max-height: $modalHeight;
      width: $modalWidth;
      max-width: $modalWidth;

      box-shadow: 0 10px 100px $greyColor;
    }
  }
}
