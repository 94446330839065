@use 'sass:math';
@import './../../styles/_variables.scss';

.app {
  height: 100%;
  width: 100%;

  .app-container {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.middle-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  background: $bgColor;
  overflow-y: auto;

  .app-page {
    flex: 1;
    max-width: $maxContainerWidth;
    padding: 0 $padding $padding;

    &.small {
      max-width: math.div($maxContainerWidth * 2, 3);
    }
  }
}
