@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes HeightIn {
  from {
    max-height: 0;
  }
  to {
    max-height: 100%;
  }
}
