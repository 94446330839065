html, body {
  height: 100%;
  overscroll-behavior: none;
  margin: 0;
  overflow: hidden;
}

html, body, h1, h2, h3, ul, li, a, i, select, label, input, div, span, button, small, strong, p {
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  text-decoration: none;
  list-style: none;
}



.auth {
  text-align: center;
  background: #faf9ff;
}

.button {
  min-width: 100px;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  background: #7f96ff;
  border: none;
  border-radius: 8px;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 20px;
  font-weight: 600;
  text-decoration: none;
  transition: background .2s ease-in-out;
  display: flex;
}

.button:hover:not(.disabled) {
  cursor: pointer;
  background: #6681ff;
}

.button.disabled {
  cursor: not-allowed;
  background-color: #a2aab3;
}

.button.red {
  background: #e66;
}

.button.grey {
  background: #a2aab3;
}

.button.grey:hover {
  background: #86909c;
}

.button.small {
  min-width: 80px;
  padding: 5px 10px;
  font-size: 11px;
}

.button.small svg {
  height: 13px;
}

.button.large {
  font-size: 15px;
}

.button svg {
  height: 15px;
  stroke: #fff;
}

.button > :not(:last-child) {
  margin-right: 6px;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes HeightIn {
  from {
    max-height: 0;
  }

  to {
    max-height: 100%;
  }
}

.page--login {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.page--login h1 {
  margin-bottom: 28px;
  font-weight: bold;
}

.page--login form {
  flex-direction: column;
  display: flex;
}

.page--login .fadeIn {
  animation: FadeIn .5s ease-in-out;
}

.page--magic-link {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.page--magic-link h1 {
  margin-bottom: 28px;
  font-weight: bold;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes HeightIn {
  from {
    max-height: 0;
  }

  to {
    max-height: 100%;
  }
}

.navigation-menu {
  height: 100%;
  min-height: 0;
  max-height: 100vh;
  width: 220px;
  z-index: 1;
  background: #faf9ff;
  flex-direction: column;
  flex-shrink: 0;
  font-weight: 500;
  display: flex;
  box-shadow: 1px 0 #f6f4ff;
}

.navigation-menu--content {
  flex-direction: column;
  flex: 1;
  padding: 20px;
  display: flex;
}

.navigation-menu--content a {
  color: #020a13;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: 600;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: flex;
}

.navigation-menu--content a svg {
  width: 22px;
  height: 22px;
  margin-right: 20px;
}

.navigation-menu--content a:hover:not(.active) {
  background: #faf9ff;
}

.navigation-menu--content a.active {
  color: #faf9ff;
  background: #7f96ff;
}

.navigation-menu--footer {
  align-items: center;
  padding: 20px;
  display: flex;
}

.navigation-menu--footer img {
  height: 28px;
  border-radius: 8px;
}

.navigation-menu--footer .navigation-menu--footer-name {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (max-width: 900px) {
  .navigation-menu {
    width: auto;
  }

  .navigation-menu .navigation-menu--content a svg {
    margin: 0;
  }

  .navigation-menu .navlink-text {
    display: none;
  }
}

.app {
  height: 100%;
  width: 100%;
}

.app .app-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.middle-container {
  background: #faf9ff;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  overflow-y: auto;
}

.middle-container .app-page {
  max-width: 1100px;
  flex: 1;
  padding: 0 20px 20px;
}

.middle-container .app-page.small {
  max-width: 733.333px;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes HeightIn {
  from {
    max-height: 0;
  }

  to {
    max-height: 100%;
  }
}

.accounts .accounts-graphs {
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.accounts .accounts-graphs .accounts-graphs--graph {
  background-color: #fff;
  border-radius: 16px;
  padding: 10px 20px;
}

.accounts .accounts-graphs .accounts-graphs--graph h3 {
  text-align: center;
  margin-bottom: 20px;
}

.accounts .accounts-graphs .accounts-graphs--graph .recharts-cartesian-axis-line {
  fill: red;
}

.accounts .accounts-graphs .accounts-graphs--graph text {
  fill: #a2aab3;
  font-size: 11px;
}

.accounts .accounts-graphs .accounts-graphs--graph .accounts-graphs--benefits {
  text-align: center;
  font-size: 11px;
}

@media only screen and (max-width: 1200px) {
  .accounts .accounts-graphs {
    grid-template-columns: 1fr;
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes HeightIn {
  from {
    max-height: 0;
  }

  to {
    max-height: 100%;
  }
}

.flying-menu {
  position: relative;
}

.flying-menu .modal-background {
  z-index: 200;
}

.flying-menu .flying-menu--block {
  min-width: 180px;
  max-height: 345px;
  text-align: left;
  color: #020a13;
  z-index: 210;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  animation: FadeIn .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 4px #a2aab3, inset 0 0 1px #7f96ff;
}

.flying-menu .flying-menu--block input {
  border-radius: 8px 8px 0 0;
}

.flying-menu .flying-menu--block ul {
  flex: 1;
  overflow-y: auto;
}

.flying-menu .flying-menu--block ul li {
  cursor: pointer;
  padding: 10px;
}

.flying-menu .flying-menu--block ul li:hover, .flying-menu .flying-menu--block ul li.focused {
  background: #faf9ff;
}

.flying-menu .flying-menu--block.bottom {
  top: auto;
  bottom: 0;
}

.flying-menu .flying-menu--block.right {
  left: auto;
  right: 0;
}

.flying-menu .flying-menu--block.full-screen {
  height: 430px;
  max-height: 430px;
  width: 400px;
  max-width: 400px;
  box-shadow: 0 10px 100px #a2aab3;
  position: fixed !important;
  inset: calc(50% - 215px) auto auto calc(50% - 90px) !important;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes HeightIn {
  from {
    max-height: 0;
  }

  to {
    max-height: 100%;
  }
}

.transactions .subtitle {
  height: 13px;
  margin-top: 5px;
  font-size: 11px;
  font-weight: normal;
}

.transactions .subtitle .loading {
  min-width: 180px;
}

.transactions .filter {
  align-items: end;
  display: flex;
}

.transactions .filter svg {
  height: 22px;
  cursor: pointer;
  stroke: #a2aab3;
}

.transactions .filter svg:hover {
  stroke: #6681ff;
}

.transactions .transactions--load-more {
  color: #99abff;
  cursor: pointer;
  margin-top: 20px;
}

.transactions .transactions--load-more:hover {
  color: #7f96ff;
}

.transactions .transactions--search {
  margin: 20px 0;
}

.transactions .transaction {
  border-radius: 8px;
  margin-top: 10px;
  padding: 10px;
  transition: background .2s ease-in-out;
}

.transactions .transaction:hover {
  background: #fefeff;
}

.transactions .transaction.focused, .transactions .transaction.selected {
  background: #fff;
  box-shadow: inset 0 0 1px #7f96ff;
}

.transactions .transaction.selected .transaction--detail {
  max-height: 200px;
}

.transactions .transaction.pending {
  color: #a2aab3;
}

.transactions .transaction.pending img {
  z-index: 0;
  filter: grayscale();
  opacity: .5;
}

.transactions .transaction--summary {
  align-items: center;
  display: flex;
}

.transactions .transaction--summary .transaction--block {
  min-height: 30px;
  align-items: center;
  margin: 0 10px;
  display: flex;
}

.transactions .transaction--summary .transaction--emoji {
  width: 2%;
  min-width: 10px;
  text-align: center;
  cursor: pointer;
}

.transactions .transaction--summary .transaction--name {
  width: 38%;
  cursor: pointer;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.transactions .transaction--summary .transaction--name .transaction--name-block {
  font-weight: 600;
}

.transactions .transaction--summary .transaction--name svg {
  height: 15px;
  stroke: #26bf88;
  margin-right: 10px;
}

.transactions .transaction--summary .transaction--name .transaction--pending {
  margin-left: 10px;
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
}

.transactions .transaction--summary .transaction--amount {
  min-width: 70px;
  width: 12%;
  text-align: right;
  justify-content: end;
  display: flex;
}

.transactions .transaction--summary .transaction--amount .digits {
  font-size: .7em;
}

.transactions .transaction--summary .transaction--created-at {
  min-width: 100px;
  width: 12%;
  color: #99abff;
  text-align: center;
  justify-content: center;
  font-size: 11px;
  display: flex;
}

.transactions .transaction--summary .transaction--project {
  min-width: 100px;
  width: 16%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.transactions .transaction--summary .transaction--project .transaction--project-block {
  min-height: 30px;
  width: 100%;
  border-radius: 8px;
  align-items: center;
  padding: 4px 10px;
  display: flex;
}

.transactions .transaction--summary .transaction--project .transaction--project-block .transaction--project-name {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 4px;
  overflow: hidden;
}

.transactions .transaction--summary .transaction--project .transaction--project-block:hover, .transactions .transaction--summary .transaction--project .transaction--project-block.transaction--project-selected {
  cursor: pointer;
  background: #faf9ff;
}

.transactions .transaction--summary .transaction--actions {
  min-width: 80px;
  width: 15%;
  justify-content: end;
  display: flex;
}

.transactions .transaction--summary .transaction--actions .transaction--action {
  width: 28px;
  height: 28px;
  color: #99abff;
  text-align: center;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  display: flex;
  overflow: hidden;
}

.transactions .transaction--summary .transaction--actions .transaction--action.transaction--action-large {
  width: 76px;
}

.transactions .transaction--summary .transaction--actions .transaction--action:not(:last-child) {
  margin-right: 20px;
}

.transactions .transaction--summary .transaction--actions .transaction--action svg {
  height: 50%;
  stroke: #a2aab3;
}

.transactions .transaction--summary .transaction--actions .transaction--action img {
  height: 120%;
}

.transactions .transaction--summary .transaction--actions .transaction--action.canHover:hover {
  opacity: .8;
  cursor: pointer;
  box-shadow: 0 0 0 1px #6681ff;
}

.transactions .transaction--summary .transaction--actions .transaction--action.canHover:hover:not(.archived):not(.shared):not(.reimbursed) svg {
  stroke: #6681ff;
}

.transactions .transaction--summary .transaction--actions .transaction--action.archived, .transactions .transaction--summary .transaction--actions .transaction--action.shared, .transactions .transaction--summary .transaction--actions .transaction--action.reimbursed {
  background: #6681ff;
  box-shadow: 0 0 0 1px #6681ff;
}

.transactions .transaction--summary .transaction--actions .transaction--action.archived svg, .transactions .transaction--summary .transaction--actions .transaction--action.shared svg, .transactions .transaction--summary .transaction--actions .transaction--action.reimbursed svg {
  stroke: #faf9ff;
}

.transactions .transaction--summary .transaction--actions .transaction--action.reimbursed {
  box-shadow: 0 0 0 2px #26bf88;
}

.transactions .transaction--summary .transaction--chevron {
  width: 5%;
  min-width: 20px;
  cursor: pointer;
  justify-content: end;
  display: flex;
}

.transactions .transaction--summary .transaction--chevron svg {
  width: 11px;
  stroke: #99abff;
}

.transactions .transaction--summary .transaction--chevron.reverse svg {
  transform: rotate(180deg);
}

.transactions .transaction--summary .transaction--sort {
  color: #99abff;
  cursor: pointer;
  font-size: 11px;
}

.transactions .transaction--summary .transaction--sort:hover {
  color: #6681ff;
}

.transactions .transaction--summary .transaction--sort.active {
  color: #6681ff;
  font-weight: 600;
}

.transactions .transaction--detail {
  box-sizing: content-box;
  max-height: 0;
  padding: 0 20px;
  font-size: 13px;
  transition: max-height .1s ease-in-out;
  display: flex;
  overflow: hidden;
}

.transactions .transaction--detail ul {
  flex: 1;
  margin-top: 20px;
}

.transactions .transaction--detail ul li {
  align-items: baseline;
  margin: 20px 0;
  display: flex;
}

.transactions .transaction--detail ul li span {
  color: #a2aab3;
  margin-left: 10px;
  font-size: 11px;
}

.transactions .transaction--detail ul li svg, .transactions .transaction--detail ul li img {
  width: 13px;
  stroke: #a2aab3;
  margin-right: 10px;
}

.transactions .transaction--detail ul li .transaction--detail-shared svg {
  stroke: #7f96ff;
}

.transactions .transaction--detail ul li .transaction--detail-archived svg {
  stroke: #a2aab3;
}

.transactions .transaction--detail ul li .transaction--detail-reimbursed svg {
  stroke: #26bf88;
}

.transactions .transaction--detail ul li .bank-name {
  font-weight: 500;
}

.transactions .transaction--detail ul li .very-small {
  color: #a2aab3;
}

.transactions .transaction--detail ul:last-child li {
  justify-content: end;
  align-items: center;
  display: flex;
}

.transactions .transaction--detail ul:last-child li a {
  text-decoration: none;
  display: flex;
}

.transactions .transaction--detail ul:last-child li a:hover {
  text-decoration: underline;
}

.transactions .transaction--detail ul:last-child li input {
  margin-right: 6px;
}

.transactions footer {
  cursor: pointer;
  color: #99abff;
  margin-top: 40px;
  font-size: 11px;
}

@media only screen and (max-width: 1200px) {
  .transaction--chevron {
    display: none !important;
  }
}

@media only screen and (max-width: 900px) {
  .transaction--project {
    min-width: 20px !important;
  }

  .transaction--project .transaction--project-block {
    justify-content: center;
  }

  .transaction--project .transaction--project-name {
    display: none !important;
  }
}

.jauged-category > div {
  margin-bottom: 20px;
}

.jauged-category .jauged-category--title {
  color: #99abff;
  font-weight: 600;
  text-decoration: none;
}

.jauged-category .jauged-category--title:hover {
  color: #7f96ff;
}

.jauged-category .jauged-category--total {
  color: #a2aab3;
  font-size: 11px;
  font-weight: 600;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes HeightIn {
  from {
    max-height: 0;
  }

  to {
    max-height: 100%;
  }
}

.statistics .statistics-blocks {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.statistics .statistics-blocks .statistic-block {
  flex-grow: 1;
  padding: 20px;
}

.statistics .statistics-blocks .statistic-block.statistic-block-2 {
  width: 50%;
  max-width: 50%;
}

.statistics .statistics-blocks .statistic-block.statistic-block-3 {
  width: 33.3%;
  max-width: 33.3%;
}

.statistics .statistics-blocks .statistic-block.statistic-block-4 {
  width: 25%;
  max-width: 25%;
}

.statistics .statistics-blocks .statistic-block.statistic-block-5 {
  width: 20%;
  max-width: 20%;
}

.statistics .statistics-blocks .statistic-block .statistic-block-content {
  background: #fff;
  border-radius: 16px;
  padding: 20px;
}

.statistics .statistics-blocks .statistic-block .statistic-block-content .statistic-block--link {
  text-align: center;
  color: #a2aab3;
  margin-top: 13px;
  font-size: 11px;
  display: block;
}

.statistics .statistics-blocks .statistic-block .statistic-block--title {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}

.statistics .statistics-blocks .statistic-block .statistic-block--title div {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.statistics .statistics-blocks .statistic-block .statistic-block--title svg {
  opacity: 0;
  height: 13px;
  stroke: #020a13;
  transition: opacity .2s ease-in-out;
}

.statistics .statistics-blocks .statistic-block .statistic-block--title:hover svg {
  opacity: 1;
}

.statistics .statistics-blocks .statistic-block .statistic-block--sum {
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.statistics .statistics-blocks .statistic-block .statistic-block--sum .statistic-block--label {
  color: var(--customColor, #a2aab3);
  flex: 1;
  align-items: center;
  font-size: 11px;
  text-decoration: none;
  display: flex;
}

.statistics .statistics-blocks .statistic-block .statistic-block--sum .statistic-block--label img, .statistics .statistics-blocks .statistic-block .statistic-block--sum .statistic-block--label svg {
  height: 15px;
  border-radius: 5.33333px;
  margin-right: 10px;
}

.statistics .statistics-blocks .statistic-block .statistic-block--hidden-title {
  cursor: pointer;
  color: #a2aab3;
  margin-top: 20px;
  font-size: 11px;
  display: flex;
}

.statistics .statistics-blocks .statistic-block .statistic-block--hidden-title span {
  width: 13px;
}

.statistics .statistics-blocks .statistic-block .statistic-block--hidden {
  height: 0;
  overflow: hidden;
}

.statistics .statistics-blocks .statistic-block .statistic-block--hidden.open {
  height: auto;
}

.statistics .statistics-blocks .statistic-block .statistic-block--hidden .statistic-block--sum {
  margin-top: 10px;
  font-size: 9px;
}

.statistics .statistics-blocks .statistic-block li {
  cursor: default;
  margin-top: 10px;
}

.statistics .statistics-blocks .statistic-block li .statistic-block--category {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  font-size: 11px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.statistics .statistics-blocks .statistic-block li .statistic-block--category svg {
  opacity: 0;
  height: 13px;
  stroke: #020a13;
  transition: opacity .2s ease-in-out;
}

.statistics .statistics-blocks .statistic-block li a {
  text-decoration: none;
}

.statistics .statistics-blocks .statistic-block li a:hover svg {
  opacity: 1;
}

.statistics .statistics-blocks .statistic-block li .statistic-block--jauge {
  height: 15px;
  min-width: 10px;
  background: var(--customColor, #a2aab3);
  color: #0006;
  border-radius: 5.33333px;
  align-items: center;
  font-size: 9px;
  font-weight: 500;
  display: flex;
  position: relative;
}

.statistics .statistics-blocks .statistic-block li .statistic-block--jauge-label {
  width: 100px;
  text-align: right;
  position: absolute;
  right: 4px;
}

.statistics .statistics-blocks .statistic-block li .statistic-block--jauge-label.right {
  text-align: left;
  right: -104px;
}

.statistics .statistics-blocks .statistic-block li .negative .statistic-block--category {
  color: #a2aab3;
}

.statistics .statistics-blocks .statistic-block li .negative .statistic-block--jauge {
  background: none;
  border: 1px dashed #a2aab3;
}

.statistics .statistics-blocks .statistic-block .statistic-balance, .statistics .statistics-blocks .statistic-block .statistic-owning {
  height: 200px;
}

.statistics .statistics-blocks .statistic-block .statistic-balance {
  justify-content: space-between;
  align-items: end;
  display: flex;
}

.statistics .statistics-blocks .statistic-block .statistic-balance .statistic-graph {
  height: 0;
  min-height: 40%;
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 13px;
  font-weight: 600;
  transition: height .5s ease-out;
  display: flex;
}

.statistics .statistics-blocks .statistic-block .statistic-balance .statistic-graph:not(:last-child) {
  margin-right: 20px;
}

.statistics .statistics-blocks .statistic-block .statistic-balance .statistic-graph.statistic-graph--current-user {
  color: #6681ff;
  background: #fed;
}

.statistics .statistics-blocks .statistic-block .statistic-balance .statistic-graph.statistic-graph--friend {
  color: #fff;
  background: #99abff;
}

.statistics .statistics-blocks .statistic-block .statistic-balance .statistic-graph.statistic-graph--joint {
  color: #fff;
  background: #26bf88;
}

.statistics .statistics-blocks .statistic-block .statistic-balance .statistic-graph img {
  width: 22px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.statistics .statistics-blocks .statistic-block .statistic-owning {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.statistics .statistics-blocks .statistic-block .statistic-owning .statistic-owning--title {
  font-weight: 500;
}

.statistics .statistics-blocks .statistic-block .statistic-owning .statistic-owning--balance {
  margin: 10px 0 20px;
  font-size: 22px;
  font-weight: 600;
}

.statistics .show-more {
  color: #a2aab3;
  cursor: pointer;
  font-size: 11px;
  font-weight: normal;
}

@media only screen and (max-width: 1200px) {
  .statistic-block {
    padding: 10px !important;
  }
}



@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes HeightIn {
  from {
    max-height: 0;
  }

  to {
    max-height: 100%;
  }
}

.settings .settings--header img {
  width: 56px;
  height: 56px;
  background: #fff;
  border-radius: 8px;
}

.settings .settings--header .settings--header-info {
  margin-left: 10px;
}

.settings .settings--header .settings--header-info .settings--header-name {
  font-size: 15px;
  font-weight: 600;
}

.settings .settings--accounts li {
  margin-bottom: 40px;
}

.settings .settings--accounts li .account-header {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.settings .settings--accounts li .account-header img {
  height: 22px;
  margin-right: 10px;
}

.settings .settings--accounts li .account-header .institution-name {
  flex: 1;
  font-size: 13px;
  font-weight: 600;
}

.settings .settings--accounts li .account-header .account-trash {
  cursor: pointer;
  margin-left: 13px;
}

.settings .settings--accounts li .account-header .account-trash svg {
  height: 15px;
  stroke: #99abff;
  transition: all .1s ease-in-out;
}

.settings .settings--accounts li .account-header .account-trash:hover svg {
  stroke: #6681ff;
}

.settings .settings--accounts li .account-list .account {
  justify-content: space-between;
  padding: 10px 0;
  font-size: 11px;
  display: flex;
}

.settings .settings--accounts li .account-list .account strong {
  font-weight: 500;
}

.settings .settings--accounts li .account-list .account .account-subtype {
  color: #a2aab3;
}

.settings .settings--accounts li .account-list .account input {
  width: 90px;
  text-align: right;
  margin-right: 20px;
  position: relative;
}

.settings .settings--accounts li .account-list .account:not(:last-child) {
  box-shadow: 0 1px #f2efff;
}

.settings #apple-import-input {
  z-index: -1;
  opacity: 0;
  height: .01px;
  width: .01px;
  position: absolute;
}

body {
  color: #020a13;
  -webkit-font-smoothing: antialiased;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
}

::selection {
  -webkit-text-fill-color: #faf9ff;
  color: #faf9ff;
  background: #99abff;
}

h1 {
  margin-top: 28px;
  font-size: 28px;
  font-weight: 700;
}

h2 {
  margin: 44px 0 22px;
  font-size: 22px;
}

h3 {
  margin: 5px 0;
  font-size: 15px;
  font-weight: 600;
}

p {
  margin: 10px 0;
}

a {
  cursor: pointer;
  color: #020a13;
  text-decoration: underline;
}

small, .small {
  font-size: 11px;
}

strong, .strong {
  font-weight: 600;
}

.very-small {
  font-size: 6.5px;
}

.code {
  color: #fed;
  background: #fed;
  border-radius: 4px;
  padding: 2px 4px;
  font-family: courier, sans-serif;
  display: inline-block;
}

.striked {
  text-decoration: line-through;
}

.exceptional {
  background: linear-gradient(to bottom left, #26bf88 0%, #7f96ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.magic {
  background: linear-gradient(to bottom left, #ffbc65 0%, #e66 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.margin-left {
  margin-left: .3em;
}

.margin-right {
  margin-right: .3em;
}

.center {
  text-align: center;
}

.df {
  flex: 1;
  display: flex;
}

.df-r {
  justify-content: end;
  display: flex;
}

.df-c {
  justify-content: center;
  display: flex;
}

.df-sb {
  justify-content: space-between;
  display: flex;
}

.df-aic {
  align-items: center;
  display: flex;
}

.df-aie {
  align-items: end;
  display: flex;
}

.ps {
  backdrop-filter: blur(20px);
  position: sticky;
  top: 0;
}

.green {
  color: #26bf88;
}

.selectable {
  -webkit-user-select: text !important;
}

.non-selectable {
  -webkit-user-select: none !important;
}

.modal-background {
  background: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

i {
  font-style: italic;
}

hr {
  border: none;
  border-top: 1px solid #e5e0ff;
  margin: 30px 0;
}

.loading {
  height: 1em;
  width: 100%;
  background: #1b329b0d;
  border-radius: 8px;
}

#root {
  height: 100%;
}

label {
  cursor: pointer;
  align-items: center;
  display: flex;
}

select {
  color: #6681ff;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5.33333px;
  font-size: 13px;
  font-weight: 400;
  display: block;
  box-shadow: 0 0 0 1px #99abff;
}

select:hover {
  box-shadow: 0 0 0 1px #7f96ff;
}

input {
  width: 100%;
  border-radius: 8px;
  outline: none;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 400;
  transition: border .2s ease-in-out;
  display: block;
  box-shadow: inset 0 0 1px #7f96ff;
}

input.short {
  width: 50%;
}

input.noBorderRadius {
  border-radius: 0;
}

input.big {
  padding: 10px;
}

input::placeholder {
  color: #a2aab3;
}

input:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

input:hover:not(:disabled), input:focus {
  border-color: #fed;
}

input[type="checkbox"] {
  cursor: pointer;
  height: 13px;
  width: 13px;
  box-shadow: none;
  border: none;
  margin-right: 1em;
  position: relative;
}

input[type="checkbox"]:before {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  border: 2px solid #7f96ff;
  border-radius: 4px;
  display: block;
  position: absolute;
  top: -3px;
  left: -3px;
}

input[type="checkbox"]:checked:before {
  background: #7f96ff;
}

input[type="checkbox"]:checked:after {
  content: "";
  width: 4px;
  height: 8px;
  border: 2px solid #fff;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 3px;
  transform: rotate(45deg);
}

/*# sourceMappingURL=index.67c68707.css.map */
