@use 'sass:math';
@import './../../styles/_variables.scss';
@import './../../styles/_mixins.scss';

.statistics {
  .statistics-blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .statistic-block {
      flex-grow: 1;
      padding: $padding;

      &.statistic-block-2 {
        width: 50%;
        max-width: 50%;
      }
      &.statistic-block-3 {
        width: 33.3%;
        max-width: 33.3%;
      }
      &.statistic-block-4 {
        width: 25%;
        max-width: 25%;
      }
      &.statistic-block-5 {
        width: 20%;
        max-width: 20%;
      }

      .statistic-block-content {
        padding: $padding $padding $padding $padding;
        background: $whiteColor;
        border-radius: $borderRadiusBig;

        .statistic-block--link {
          display: block;
          margin-top: $pSize;
          font-size: $smallSize;
          text-align: center;
          color: $greyColor;
        }
      }

      .statistic-block--title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $padding;
        font-size: $pSize;
        font-weight: 600;
        text-decoration: none;

        div {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        svg {
          opacity: 0;
          height: $pSize;
          stroke: $textColor;
          transition: opacity 0.2s ease-in-out;
        }

        &:hover svg {
          opacity: 1;
        }
      }

      .statistic-block--sum {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $padding;

        .statistic-block--label {
          display: flex;
          flex: 1;
          align-items: center;
          font-size: $smallSize;
          color: var(--customColor, $greyColor);
          text-decoration: none;

          img,
          svg {
            height: $h3Size;
            border-radius: $borderRadiusSmall;
            margin-right: $paddingSmall;
          }
        }
      }

      .statistic-block--hidden-title {
        display: flex;
        margin-top: $padding;
        cursor: pointer;
        font-size: $smallSize;
        color: $greyColor;

        span {
          width: $pSize;
        }
      }

      .statistic-block--hidden {
        height: 0;
        overflow: hidden;

        &.open {
          height: auto;
        }
        .statistic-block--sum {
          margin-top: $paddingSmall;
          font-size: $verySmallSize;
        }
      }

      li {
        margin-top: $paddingSmall;
        cursor: default;

        .statistic-block--category {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: $smallSize;
          font-weight: 500;
          margin-bottom: 4px;
          text-decoration: none;

          svg {
            opacity: 0;
            height: $pSize;
            stroke: $textColor;
            transition: opacity 0.2s ease-in-out;
          }
        }

        a {
          text-decoration: none;

          &:hover svg {
            opacity: 1;
          }
        }

        .statistic-block--jauge {
          position: relative;
          display: flex;
          align-items: center;
          height: $h3Size;
          min-width: 10px;
          background: var(--customColor, $greyColor);
          font-size: 9px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
          border-radius: $borderRadiusSmall;
        }

        .statistic-block--jauge-label {
          position: absolute;
          right: 4px;
          width: 100px;
          text-align: right;

          &.right {
            text-align: left;
            right: -(104px);
          }
        }

        .negative {
          .statistic-block--category {
            color: $greyColor;
          }

          .statistic-block--jauge {
            background: none;
            border: 1px dashed $greyColor;
          }
        }
      }

      .statistic-balance,
      .statistic-owning {
        height: 200px;
      }

      .statistic-balance {
        display: flex;
        justify-content: space-between;
        align-items: end;

        .statistic-graph {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          flex: 1;
          padding: $padding;
          font-size: $pSize;
          font-weight: 600;
          border-radius: $borderRadiusBig;
          height: 0;
          min-height: 40%;
          transition: height 0.5s ease-out;

          &:not(:last-child) {
            margin-right: $padding;
          }

          &.statistic-graph--current-user {
            background: $antique-white;
            color: $primaryDarkenColor;
          }
          &.statistic-graph--friend {
            color: $whiteColor;
            background: $primaryLightenColor;
          }
          &.statistic-graph--joint {
            color: $whiteColor;
            background: $greenColor;
          }
          img {
            width: $h2Size;
            margin-bottom: $paddingSmall;
            border-radius: $borderRadius;
          }
        }
      }

      .statistic-owning {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .statistic-owning--title {
          font-weight: 500;
        }
        .statistic-owning--balance {
          font-size: $h2Size;
          font-weight: 600;
          margin: $paddingSmall 0 $padding;
        }
      }
    }
  }

  .show-more {
    color: $greyColor;
    font-size: $smallSize;
    font-weight: normal;
    cursor: pointer;
  }
}

@media only screen and (max-width: $maxLaptop) {
  .statistic-block {
    padding: $paddingSmall !important;
  }
}
