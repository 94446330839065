@use 'sass:math';
@import './../../styles/_variables.scss';

.button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 100px;
  padding: $paddingSmall $padding;
  background: $primaryColor;
  border: none;
  border-radius: $borderRadius;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  color: $whiteColor;
  white-space: nowrap;
  transition: background 0.2s ease-in-out;

  &:hover:not(.disabled) {
    cursor: pointer;
    background: $primaryDarkenColor;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $greyColor;
  }

  &.red {
    background: $redColor;
  }
  &.grey {
    background: $greyColor;
    &:hover {
      background: darken($greyColor, 10%);
    }
  }
  &.small {
    min-width: 80px;
    padding: math.div($paddingSmall, 2) $paddingSmall;
    font-size: $smallSize;

    svg {
      height: $pSize;
    }
  }
  &.large {
    font-size: $h3Size;
  }

  svg {
    height: $h3Size;
    stroke: $whiteColor;
  }

  & > *:not(:last-child) {
    margin-right: 6px;
  }
}
