@use 'sass:math';
@import './../../styles/_variables.scss';

body {
  font-family: $fontFamilySansSerif;
  font-size: $pSize;
  font-weight: 400;
  color: $textColor;
  -webkit-font-smoothing: antialiased;
}

::selection {
  background: $primaryLightenColor;
  -webkit-text-fill-color: $bgColor;
  color: $bgColor;
}

h1 {
  font-weight: 700;
  font-size: $h1Size;
  margin-top: $h1Size;
}

h2 {
  font-size: $h2Size;
  margin: $h2Size * 2 0 $h2Size;
}

h3 {
  font-size: $h3Size;
  margin: math.div($paddingSmall, 2) 0;
  font-weight: 600;
}

p {
  margin: $paddingSmall 0;
}

a {
  text-decoration: underline;
  cursor: pointer;
  color: $textColor;
}

small,
.small {
  font-size: $smallSize;
}

strong,
.strong {
  font-weight: 600;
}

.very-small {
  font-size: math.div($pSize, 2);
}

.code {
  display: inline-block;
  padding: 2px 4px;
  background: $hoverColor;
  font-family: $fontFamilyCourier;
  color: $hoverColor;
  border-radius: math.div($borderRadius, 2);
}

.striked {
  text-decoration: line-through;
}

.exceptional {
  background: linear-gradient(to bottom left, $greenColor 0%, $blueColor 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.magic {
  background: linear-gradient(to bottom left, $orangeColor 0%, $redColor 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.margin-left {
  margin-left: 0.3em;
}
.margin-right {
  margin-right: 0.3em;
}

.center {
  text-align: center;
}
.df {
  display: flex;
  flex: 1;
}
.df-r {
  display: flex;
  justify-content: end;
}
.df-c {
  display: flex;
  justify-content: center;
}
.df-sb {
  display: flex;
  justify-content: space-between;
}
.df-aic {
  display: flex;
  align-items: center;
}
.df-aie {
  display: flex;
  align-items: end;
}
.ps {
  position: sticky;
  top: 0;
  backdrop-filter: blur(20px);
}
.green {
  color: $greenColor;
}
.selectable {
  -webkit-user-select: text !important;
}

.non-selectable {
  -webkit-user-select: none !important;
}

.modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  overflow: hidden;
}
i {
  font-style: italic;
}
hr {
  border: none;
  border-top: 1px solid darken($bgColor, 5%);
  margin: $paddingBig 0;
}

.loading {
  height: 1em;
  width: 100%;
  background: rgba(27, 50, 155, 0.05);
  border-radius: $borderRadius;
}

#root {
  height: 100%;
}

@import './../../styles/inputs.scss';
