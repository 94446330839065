@use 'sass:math';
@import './../../styles/_variables.scss';
@import './../../styles/_mixins.scss';

.settings {
  .settings--header {
    img {
      border-radius: $borderRadius;
      width: $h1Size * 2;
      height: $h1Size * 2;
      background: $whiteColor;
    }

    .settings--header-info {
      margin-left: $paddingSmall;

      .settings--header-name {
        font-size: $h3Size;
        font-weight: 600;
      }
    }
  }

  .settings--accounts {
    li {
      margin-bottom: $padding * 2;

      .account-header {
        display: flex;
        align-items: center;
        margin-bottom: $paddingSmall;

        img {
          height: $h2Size;
          margin-right: $paddingSmall;
        }
        .institution-name {
          flex: 1;
          font-weight: 600;
          font-size: $pSize;
        }

        .account-trash {
          cursor: pointer;
          margin-left: $pSize;
          svg {
            height: $h3Size;
            stroke: $primaryLightenColor;
            transition: all 0.1s ease-in-out;
          }
          &:hover svg {
            stroke: $primaryDarkenColor;
          }
        }
      }

      .account-list {
        .account {
          display: flex;
          justify-content: space-between;
          padding: $paddingSmall 0;
          font-size: $smallSize;

          strong {
            font-weight: 500;
          }
          .account-subtype {
            color: $greyColor;
          }

          input {
            position: relative;
            margin-right: $padding;
            width: 90px;
            text-align: right;
          }

          &:not(:last-child) {
            box-shadow: 0 1px 0 0px darken($bgColor, 2%);
          }
        }
      }
    }
  }

  #apple-import-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    height: 0.01px;
    width: 0.01px;
  }
}
